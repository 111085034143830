import { makeObservable, observable, action } from "mobx";
import { cloneDeep } from 'lodash';
import { generateId } from '@/utils/utils';
import service from "@/services/axios";
import { message } from 'antd';
import { ElementData, ElementContent } from "@/base/ElementData/ElementData";
import { BlockData, BlockTraceData } from "@/base/BlockData";
import ProgressSegment from "@/base/ProgressSegmentData";
import { userInfoStore } from './userInfoStore';
import { genBlockTraceDatafromJSON } from "@/base/DataObjectGenerator";
import DataDefinition from "@/base/DataDefinition";
import AIChatRegionContent from "@/base/ElementData/AIChatRegionContent";
import { ChatMessage } from "@/base/ChatMessage";

interface ElementRefItem {
    id: number;
    ref: React.RefObject<any>;
}

class LectureStore {
    originBlockList: BlockData[] = [];
    learnedBlockList: BlockTraceData[] = [];
    lectureVars: Record<string, any> = {};
    sectionTraceId: number = 0;
    canNextStep: boolean = true;
    currentBlockIndex: number = 0;
    partSegments: ProgressSegment[] = [];
    allAudioUrls: string[] = [];
    elementRefs: ElementRefItem[] = [];
    // current editing Block
    block: BlockData | null = null;
    // 老师聊天的模块背后的数据放这里, 这里需要使用mode 1
    teacherChat: AIChatRegionContent = new AIChatRegionContent({ mode: 1, ttsVoice: "BV700_streaming" });
    courseBGM: string = '';
    courseBGMAudioElement: HTMLAudioElement | null = null;

    constructor() {
        this.partSegments = localStorage.getItem('partSegments') ? JSON.parse(localStorage.getItem('partSegments') as string) : [];
        makeObservable(this, {
            originBlockList: observable,
            learnedBlockList: observable,
            lectureVars: observable,
            sectionTraceId: observable,
            canNextStep: observable,
            currentBlockIndex: observable,
            partSegments: observable,
            allAudioUrls: observable,
            elementRefs: observable,
            block: observable,
            teacherChat: observable,
            courseBGM: observable,
            courseBGMAudioElement: observable,
            setLectureVars: action,
            setLectureVar: action,
            delLectureVar: action,
            getLectureVar: action,
            getCurrentBlockContent: action,
            setOriginBlockList: action,
            setLearnedBlockList: action,
            setSectionTraceId: action,
            setCanNextStep: action,
            setCurrentBlockIndex: action,
            setPartSegments: action,
            setCourseBGM: action,
            playCourseBGM: action,
            pauseCourseBGM: action,
            resetCourseBGM: action,
            setAllAudioUrls: action,
            addBlockTrace: action,
            updateElement: action,
            findBlockById: action,
            addLearnedBlock: action,
            nextStep: action,
            continueFunc: action,
            previousStep: action,
            resetLectureStore: action,
            setElementRefs: action,
            setBlock: action,
            setTeacherChat: action
        });
    }

    setOriginBlockList(value: BlockData[]) {
        this.originBlockList = value;
    }

    setLearnedBlockList(value: BlockTraceData[]) {
        this.learnedBlockList = value;
    }

    setLectureVars(value: any) {
        this.lectureVars = value;
    }

    setLectureVar(key: string, value: any) {
        // 因为legacy的问题，key可能是snake_case，所以这里要转换
        // 如果已经是camelCase，调用toCamelCase不会改变key
        key = DataDefinition.toCamelCase(key);
        this.lectureVars[key] = value;
    }

    delLectureVar(key: string) {
        delete this.lectureVars[key];
    }

    getLectureVar(key: string): any {
        // 这里legacy的key （在数据库里的）可能是snake_case
        // 如果已经是camelCase，调用toCamelCase不会改变key
        key = DataDefinition.toCamelCase(key);

        // 
        // 如何key不存在， 需要从blockTrace里面去找
        if (!this.lectureVars[key]) {
            let block = this.learnedBlockList[this.currentBlockIndex];
            //console.log('getLectureVar -- block', block);
            if (block?.extras) {
                //console.log('block.extras exist', block.extras.length);
                if (block.extras[key]) {
                    // 实际上是setLectureVar
                    // 这里会导致lectureVars里的key都是camelCase
                    this.lectureVars[key] = block.extras[key];
                    console.log('got from block extras');
                } else if (block.extras.lectureConstants && block.extras.lectureConstants[key]) {
                    this.lectureVars[key] = block.extras.lectureConstants[key];
                    console.log('got from block extras lecture_constants');
                } else {
                    console.log('extras.lecture_constants[key] is null');
                }
            } else {
                console.log('block or extras is null');
            }
        }
        return this.lectureVars[key];
    }

    getCurrentBlockContent() {
        const content: string = this.learnedBlockList[this.currentBlockIndex].getReadableContent();
        console.log('getCurrentBlockContent', content);
        return content;
    }

    setSectionTraceId(value: number) {
        this.sectionTraceId = value;
    }

    setCanNextStep(value: boolean) {
        this.canNextStep = value;
    }

    setCurrentBlockIndex(value: number) {
        this.currentBlockIndex = value;
    }

    setPartSegments(value: ProgressSegment[]) {
        this.partSegments = value;
    }

    setCourseBGM(bgm: string) {
        this.courseBGM = bgm;
        if (bgm) {
            this.courseBGMAudioElement = new Audio(bgm);
            this.courseBGMAudioElement.loop = true; // 设置循环播放  
            this.courseBGMAudioElement.volume = 0.5; // 设置音量   
        }
    }
    playCourseBGM() {
        if (this.courseBGMAudioElement) {
            this.courseBGMAudioElement.play(); // 播放音乐  
        }
    }

    pauseCourseBGM() {
        if (this.courseBGMAudioElement) {
            this.courseBGMAudioElement.pause(); // 暂停音乐  
        }
    }

    resetCourseBGM() {
        this.courseBGM = '';
        if (this.courseBGMAudioElement) {
            this.courseBGMAudioElement = null;
        }
    }

    setAllAudioUrls(value: string[]) {
        this.allAudioUrls = value;
    }

    setElementRefs(value: any[]) {
        this.elementRefs = value;
    }

    setBlock(blk: BlockData | null) {
        this.block = blk;
    }

    setTeacherChat(teacherChat: AIChatRegionContent) {
        //每次都需要clone去触发mobx的更新
        const chatContent = cloneDeep(teacherChat);
        this.teacherChat = chatContent;
    }

    // 在学习记录中添加对话block
    addBlockTrace(block: BlockTraceData, index: number = -1) {
        const newLearnedBlockList = [...this.learnedBlockList];
        if (index === -1) {
            newLearnedBlockList.push(block);
        } else {
            newLearnedBlockList.splice(index, 0, block);
        }
        this.setLearnedBlockList(newLearnedBlockList);
    }


    // 新版修改指定block中的指定元素
    updateElement(blockID: number, elementID: number, newContent: ElementContent) {
        //每次都需要clone去触发mobx的更新
        const newLearnedBlockList = cloneDeep(this.learnedBlockList);
        const blockIndex = newLearnedBlockList.findIndex((item) => item.id === blockID);
        const elementIndex = newLearnedBlockList[blockIndex]?.traceInfo.findIndex((item) => item.id === elementID);
        if (elementIndex === -1) {
            console.log('element not found in updateElement', blockID, elementID);
            return;
        }
        newLearnedBlockList[blockIndex].traceInfo[elementIndex].content = newContent;
        this.setLearnedBlockList(newLearnedBlockList);
    }

    //根据id找到对应block
    findBlockById(blockId: number): BlockTraceData | undefined {
        return this.learnedBlockList.find((item) => item.id === blockId);
    }

    //添加学习记录
    addLearnedBlock = async (sectionTraceId: number, block: BlockData, traceType: number): Promise<void> => {
        const learnedBlockTrace = {
            section_trace_id: sectionTraceId,
            block_trace: {
                id: generateId(),
                user_id: userInfoStore.userInfoData.id,
                orig_block_id: block.id,
                trace_type: traceType,
                trace_info: block.blockInfo,
                extras: block.extras,
                update_time: 0
            },
            offset: -1
        };

        if (block.blockInfo.every(item => item.type !== 20)) {
            try {
                const res: any = await service.put('/section_add_blk_trace', learnedBlockTrace);
                if (res.status === 'success') {
                    const newLearnedBlockList = cloneDeep(this.learnedBlockList);
                    learnedBlockTrace.block_trace.id = res.data.blk_trace_id;

                    newLearnedBlockList.push(genBlockTraceDatafromJSON(learnedBlockTrace.block_trace));
                    this.setLearnedBlockList(newLearnedBlockList);
                } else {
                    message.error("添加学习记录失败");
                }
            } catch (error) {
                console.log('error', error);
            }
        } else {
            const newLearnedBlockList = cloneDeep(this.learnedBlockList);
            newLearnedBlockList.push(genBlockTraceDatafromJSON(learnedBlockTrace.block_trace));
            this.setLearnedBlockList(newLearnedBlockList);
            this.setCanNextStep(false);
        }
    }
    //下一步    
    nextStep = async () => {
        let lastValidOrigBlockId = null;
        console.log('nextStep', this.learnedBlockList.length);
        for (let i = this.learnedBlockList.length - 1; i >= 0; i--) {
            let block = this.learnedBlockList[i];
            if (block.traceType === 1) {
                lastValidOrigBlockId = block.origBlockId;
                break;
            }
        }
        if (lastValidOrigBlockId === null) {
            console.error("Error: Cannot find a valid orig_block_id.", this.learnedBlockList);
            return;
        }
        // 根据lastValidOrigBlockId在originBlockList中找到相应的block，然后添加其下一项到learnedBlockList
        let nextBlock = null;
        for (let i = 0; i < this.originBlockList.length; i++) {
            if (this.originBlockList[i].id === lastValidOrigBlockId) {
                nextBlock = this.originBlockList[i + 1];
                break;
            }
        }

        if (nextBlock) {
            const modifiedBlock = cloneDeep(nextBlock);
            console.log('nextBlock created -- modifiedBlock 0', modifiedBlock.extras);
            const currBlockTrace = this.learnedBlockList[this.currentBlockIndex];
            if (currBlockTrace.extras && Object.keys(currBlockTrace.extras).length > 0) {
                // 这里把前一个block的extras copy到 下一个block
                // 去掉 postProcessHandlers, rewardedCoinsNum，不要带到下一个block
                const { postProcessHandlers, rewardedCoinsNum, ...filteredExtras } = currBlockTrace.extras;
                modifiedBlock.extras = { ...filteredExtras, ...modifiedBlock.extras };
                console.log('nextBlock created -- modifiedBlock 2', modifiedBlock.extras);

            }
            if (this.sectionTraceId) {
                await this.addLearnedBlock(this.sectionTraceId, modifiedBlock, 1);
                console.log('nextBlock modifiedBlock', modifiedBlock);
            }
        } else {
            console.log('没有找到下一个block');
        }
    };
    //点击继续，渲染下一个block，如果是最后一个block则调用nextStep
    continueFunc = async () => {
        if (this.currentBlockIndex < this.learnedBlockList.length - 1) {
            this.setCurrentBlockIndex(this.currentBlockIndex + 1);
        } else {
            await this.nextStep();
        }
    }
    //点击上一步，渲染上一个block，如果是第一个block则不渲染，因为没有上一个
    previousStep = () => {
        if (this.currentBlockIndex > 0) {
            this.setCurrentBlockIndex(this.currentBlockIndex - 1);
        }
    }

    // //从当前页（包括当前页）开始清空学习记录
    resetSpecifiedLearnedBlock = async (offset: number) => {
        let specifiedBlock = this.learnedBlockList[offset - 1];
        //根据specifiedBlock的origBlockId在originBlockList中找到对应的block，然后添加到learnedBlockList
        if (specifiedBlock) {
            let originBlock = this.originBlockList.find((item) => item.id === specifiedBlock.origBlockId);
            if (originBlock) {
                let copiedOriginBlock = cloneDeep(originBlock);
                let blockTrace = cloneDeep(specifiedBlock);
                blockTrace.traceInfo = copiedOriginBlock.blockInfo;
                // extras不要覆盖了
                //blockTrace.extras = copiedOriginBlock.extras;
                let res: any = await service.put('/block_trace', blockTrace.toJSON());
                if (res.status === 'success') {
                    this.learnedBlockList[offset - 1] = blockTrace;
                    let newLearnedBlockList = cloneDeep(this.learnedBlockList);
                    this.setLearnedBlockList(newLearnedBlockList.slice(0, offset));
                    this.currentBlockIndex = offset - 1;
                }
            }
        }

        this.learnedBlockList[offset]
    }


    // 重置store
    resetLectureStore() {
        this.originBlockList = [];
        this.learnedBlockList = [];
        this.sectionTraceId = 0;
    }
}

const lectureStore = new LectureStore();
export { lectureStore, LectureStore };
export type { ElementRefItem };

